import axios from "../../axios-base";

export const clear = () => {
  return function (dispatch, getState) {
    dispatch(clearStart);
    dispatch(loadFastMenus);
  };
};

const errorMessage = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clearStart = () => {
  return {
    type: "CLEAR_FASTMENU",
  };
};

export const loadFastMenus = () => {
  return function (dispatch, getState) {
    dispatch(loadFastMenusStart());
    axios
      .get("fastmenus")
      .then((response) => {
        const result = response.data.data;
        dispatch(loadFastMenusSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadFastMenusError(resultError));
      });
  };
};
export const loadFastMenusStart = () => {
  return {
    type: "LOAD_FASTMENUS_START",
  };
};

export const loadFastMenusSuccess = (result) => {
  return {
    type: "LOAD_FASTMENUS_SUCCESS",
    fastmenus: result,
  };
};

export const loadFastMenusError = (error) => {
  return {
    type: "LOAD_FASTMENUS_ERROR",
    error,
  };
};

// SINGLE CATEGORY

export const getFastMenu = (id) => {
  return function (dispatch, getState) {
    dispatch(getFastMenuStart());
    axios
      .get(`fastmenus/${id}`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getFastMenuSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(getFastMenuError(resultError));
      });
  };
};

export const getFastMenuStart = () => {
  return {
    type: "GET_FASTMENU_START",
  };
};

export const getFastMenuSuccess = (result) => {
  return {
    type: "GET_FASTMENU_SUCCESS",
    fastmenu: result,
  };
};

export const getFastMenuError = (error) => {
  return {
    type: "GET_FASTMENU_ERROR",
    error,
  };
};

// Change positions
export const changePosition = (data) => {
  return function (dispatch) {
    dispatch(changePositionStart());

    axios
      .post("fastmenus/change", data)
      .then((response) => {
        const result = response.data.data;
        dispatch(changePositionSuccess(result));
        dispatch(loadFastMenus());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(changePositionError(resultError));
      });
  };
};

export const changePositionStart = (result) => {
  return {
    type: "FASTMENUS_CHANGE_POSITION_START",
  };
};

export const changePositionSuccess = (data) => {
  return {
    type: "FASTMENUS_CHANGE_POSITION_SUCCESS",
    fastmenus: data,
  };
};

export const changePositionError = (error) => {
  return {
    type: "FASTMENUS_CHANGE_POSITION_ERROR",
    error: error,
  };
};

// DELETE CATEGORY

export const deleteFastMenu = (fastmenuId, data) => {
  return function (dispatch, getState) {
    dispatch(deleteFastMenuStart());

    axios
      .delete(`fastmenus/${fastmenuId}`, data)
      .then((response) => {
        const resultFastMenu = response.data.data;
        dispatch(deleteFastMenuSuccess(resultFastMenu));
        dispatch(loadFastMenus());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(deleteFastMenuError(resultError));
      });
  };
};

export const deleteFastMenuStart = () => {
  return {
    type: "DELETE_FASTMENU_START",
  };
};

export const deleteFastMenuSuccess = (result) => {
  return {
    type: "DELETE_FASTMENU_SUCCESS",
    dlNews: result,
  };
};

export const deleteFastMenuError = (error) => {
  return {
    type: "DELETE_FASTMENU_ERROR",
    error,
  };
};

// SAVE CATEGORY

export const saveFastMenu = (fastmenu) => {
  return function (dispatch, getState) {
    dispatch(saveFastMenuStart());
    let data = {
      name: fastmenu.name,
      status: fastmenu.status,
      isDirect: fastmenu.isDirect,
      isModel: fastmenu.isModel,
      model: fastmenu.model,
      direct: fastmenu.direct,
      cover: fastmenu.cover,
      icon: fastmenu.icon,
    };

    if (fastmenu.parentId !== null) {
      data = {
        name: fastmenu.name,
        parentId: fastmenu.parentId,
        isDirect: fastmenu.isDirect,
        isModel: fastmenu.isModel,
        model: fastmenu.model,
        direct: fastmenu.direct,
        cover: fastmenu.cover,
        icon: fastmenu.icon,
      };
    }

    data.language = fastmenu.language;
    data.status = fastmenu.status;

    axios
      .post(`fastmenus`, data)
      .then((response) => {
        const resultFastMenu = response.data.data;
        dispatch(saveFastMenuSuccess(resultFastMenu));
        dispatch(loadFastMenus());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(saveFastMenuError(resultError));
      });
  };
};

export const saveFastMenuStart = () => {
  return {
    type: "CREATE_FASTMENU_START",
  };
};

export const saveFastMenuSuccess = (resultFastMenu) => {
  return {
    type: "CREATE_FASTMENU_SUCCESS",
  };
};

export const saveFastMenuError = (error) => {
  return {
    type: "CREATE_FASTMENU_ERROR",
    error: error,
  };
};

// UPDATE CATEGORY

export const updateFastMenu = (fastmenu, id) => {
  return function (dispatch) {
    dispatch(updateFastMenuStart());

    axios
      .put(`fastmenus/${id}`, fastmenu)
      .then((response) => {
        const resultFastMenu = response.data.data;
        dispatch(updateFastMenuSuccess(resultFastMenu));
        dispatch(loadFastMenus());
        dispatch(getFastMenu(id));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(updateFastMenuError(resultError));
      });
  };
};

export const updateFastMenuStart = () => {
  return {
    type: "UPDATE_FASTMENU_START",
  };
};

export const updateFastMenuSuccess = (resultFastMenu) => {
  return {
    type: "UPDATE_FASTMENU_SUCCESS",
    resultFastMenu: resultFastMenu,
  };
};

export const updateFastMenuError = (error) => {
  return {
    type: "UPDATE_FASTMENU_ERROR",
    error: error,
  };
};
