const initialState = {
  fastmenus: [],
  loading: false,
  error: null,
  success: null,
  fastmenu: null,
  type: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_FASTMENU":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        type: null,
      };
    case "FASTMENU_CHANGE_POSITION_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        fastmenus: null,
      };
    case "FASTMENU_CHANGE_POSITION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        success: "Өөрчлөлт хадгалагдлаа",
        fastmenus: action.fastmenus,
      };
    case "FASTMENU_CHANGE_POSITION_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
        fastmenus: null,
      };

    case "LOAD_FASTMENUS_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case "LOAD_FASTMENUS_SUCCESS":
      return {
        ...state,
        fastmenus: action.fastmenus,
        loading: false,
        success: null,
        error: null,
      };

    case "LOAD_FASTMENUS_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
      };
    // Single fastmenu
    case "GET_FASTMENU_START":
      return {
        ...state,
        loading: true,
        error: null,
        fastmenu: null,
        success: null,
      };
    case "GET_FASTMENU_SUCCESS":
      return {
        ...state,
        fastmenu: action.fastmenu,
        loading: false,
        error: null,
        success: null,
      };

    case "GET_FASTMENU_ERROR":
      return {
        ...state,
        fastmenu: null,
        loading: false,
        error: action.error,
        success: null,
      };

    // save
    case "CREATE_FASTMENU_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "CREATE_FASTMENU_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай шинэ цэс нэмэгдлээ",
        error: null,
      };
    case "CREATE_FASTMENU_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };
    case "DELETE_FASTMENU_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        fastmenu: null,
      };
    case "DELETE_FASTMENU_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай цэсыг устгаллаа",
        error: null,
        fastmenu: null,
      };
    case "DELETE_FASTMENU_ERROR":
      return {
        ...state,
        error: action.error,
        loading: false,
        success: null,
        fastmenu: null,
      };

    // Update
    case "UPDATE_FASTMENU_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "UPDATE_FASTMENU_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай цэсын нэр солигдлоо",
        error: null,
      };
    case "UPDATE_FASTMENU_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
      };

    default:
      return state;
  }
};

export default reducer;
