const base = {
  // siteUrl: "http://localhost:3000/",
  // baseUrl: "http://localhost:3000/",
  // cdnUrl: "http://localhost:2001/uploads/",
  // apiUrl: "http://localhost:2001/v1/api/",
  siteUrl: "https://seruunagaar.mn/",
  baseUrl: "https://admin.seruunagaar.mn/",
  cdnUrl: "https://seruunagaar.mn/uploads/",
  apiUrl: "https://admin.seruunagaar.mn/api/",
};

export default base;
